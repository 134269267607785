<template>
  <div class="question-wrap">
    <pageTitle pageTitle="中奖用户">
      <span class="return_list"></span>
    </pageTitle>
    <div class="head-operat" v-show="total || isSearch">
      <vh-button size="medium" type="info" plain round @click="handleExport">导出明细</vh-button>
      <VhallInput
        class="search-tag"
        placeholder="请输入奖品名称/用户昵称"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="searchTableList"
        @keyup.enter.native="searchTableList"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchTableList"></i>
      </VhallInput>
    </div>
    <div class="question-list" v-show="total || isSearch">
      <table-list
        ref="tableList"
        :manageTableData="tableData"
        :tabelColumnLabel="tabelColumn"
        :isHandle="false"
        :isCheckout="false"
        :totalNum="total"
        :width="150"
        @getTableList="getTableList"
      ></table-list>
      <noData :nullType="'search'" v-if="!total"></noData>
    </div>
    <div class="no-live" v-show="!total && !isSearch">
      <noData :nullType="'nullData'" :text="'您还没有中奖数据，快去抽奖吧'"></noData>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal } from '@/utils/utils';
  export default {
    name: 'prize',
    data() {
      return {
        total: 0,
        isSearch: false,
        keyword: '',
        userId: JSON.parse(sessionOrLocal.get('userId')),
        searchAreaLayout: [
          {
            key: 'keyword'
          }
        ],
        tabelColumn: [
          {
            label: '用户名称',
            key: 'user_name'
          },
          {
            label: '手机号',
            key: 'phone'
          },
          {
            label: '奖品信息',
            key: 'award_url'
          },

          {
            label: '推送时间',
            key: 'push_time'
          },
          {
            label: '中奖时间',
            key: 'winning_time'
          }
        ],
        tableData: []
      };
    },
    components: {
      PageTitle,
      noData
    },
    mounted() {
      this.lotteryId = this.$route.query.lotteryId;
      this.getTableList();
    },
    methods: {
      handleExport() {
        let params = {
          id: this.lotteryId,
          keyword: this.keyword,
          webinar_id: this.$route.params.str
        };
        this.$fetch('exportWinnersInfo', params).then(res => {
          if (res.code === 200) {
            this.$vhMessage({
              message: `导出成功，${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
          }
        });
      },
      searchTableList() {
        this.getTableList('search');
      },
      getTableList(params) {
        let pageInfo = this.$refs.tableList.pageInfo;
        //获取分页信息
        let formParams = {
          keyword: this.keyword,
          lotteryId: this.lotteryId
        }; //获取搜索参数
        if (params == 'search') {
          pageInfo.pageNum = 1;
          pageInfo.pos = 0;
        }
        this.isSearch = this.keyword ? true : false;
        let obj = Object.assign({}, pageInfo, formParams);

        this.$fetch('getWinnersList', obj).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.tableData.map(item => {
            item.award_url = item.award_image_url;
            item.user_name = item?.user_name || '--';
            item.phone = item?.phone || '--';
          });
        });
      }
    }
  };
</script>

<style lang="less" scoped>
  .question-list {
    width: 100%;
    margin-top: 24px;
    .layout--right--main();
    .padding-table-list();
  }
  /deep/.vh-button--info.is-plain,
  /deep/.vh-input__inner {
    background: transparent;
  }
  .question-wrap {
    height: 100%;
    width: 100%;
    .return_list {
      float: right;
    }
    /deep/.vh-card__body {
      width: 100%;
      padding: 32px 24px;
    }
    .head-operat {
      margin-bottom: 20px;
      .head-btn {
        display: inline-block;
      }
      /deep/.vh-button.is-disabled {
        background: transparent;
      }
      ::v-deep .set-upload {
        position: relative;
        span {
          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      /* .inputKey{
      float: right;
      height: 35px;
      width: 220px;
      /deep/.vh-input__inner{
        border-radius: 18px;
      }
    } */
      .search-tag {
        float: right;
        width: 220px;
        /deep/ .vh-input__icon {
          line-height: 36px;
        }
        /deep/.vh-input__inner {
          border-radius: 20px;
          height: 36px;
          line-height: 36px;
          padding-right: 30px !important;
        }
        /deep/ .vh-input__prefix {
          cursor: pointer;
        }
      }
    }
    .head-prize {
      margin: 0 24px;
    }
    .prize-no {
      // height: calc(100vh - 260px);
      text-align: center;
      margin-top: 208px;
    }
    .no-live {
      padding-bottom: 150px;
    }
  }
</style>
